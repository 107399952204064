import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.function.name.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "group-item"
  }, [!_vm.showbtn ? _c("span", {
    staticClass: "sort"
  }, [_vm._v(_vm._s(_vm.item.sort))]) : _vm._e(), _c("a-avatar", {
    staticClass: "item-img",
    attrs: {
      shape: "square",
      src: _vm.item.headimgurl
    }
  }), _c("div", {
    staticClass: "right"
  }, [_c("p", {
    staticClass: "title"
  }, [_vm._v(_vm._s(_vm.item.name))]), _c("p", {
    staticClass: "desc"
  }, [_c("span", {
    staticStyle: {
      width: "120px"
    }
  }, [_vm._v("群聊人数：" + _vm._s(_vm.item.num || 0))]), _c("span", {
    staticStyle: {
      margin: "0 12px"
    },
    style: _vm.item.recommendswitch ? "" : "color: #ff4d4f;"
  }, [_vm._v("可被搜索：" + _vm._s(_vm.item.recommendswitch ? "是" : "否"))]), !_vm.showbtn && _vm.showDel ? _c("a-button", {
    staticClass: "del",
    attrs: {
      type: "link"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("remove", _vm.item);
      }
    }
  }, [_vm._v("删除")]) : _vm._e()], 1)]), _vm.showbtn ? [_c("a-icon", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.item.in_category && _vm.item.recommendswitch,
      expression: "!item.in_category && item.recommendswitch"
    }],
    staticClass: "add-btn",
    attrs: {
      type: "plus-circle"
    },
    on: {
      click: function click($event) {
        return _vm.$emit("choose", _vm.item);
      }
    }
  }), _c("span", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.item.in_category,
      expression: "item.in_category"
    }]
  }, [_vm._v("已添加")])] : _vm._e(), !_vm.showbtn && _vm.showDel ? _c("a", {
    staticClass: "drag-icon",
    attrs: {
      href: "javascript:void(0);"
    }
  }, [_c("a-icon", {
    attrs: {
      type: "swap"
    }
  }), _vm._v(" 拖拽调整排序 ")], 1) : _vm._e()], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };