import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.sort.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.push.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import groupItem from "./components/groupItem";
import draggable from "vuedraggable";
import infiniteScroll from "vue-infinite-scroll";
import { IMApi } from "@/api";
import debounce from "lodash/debounce";
export default {
  data: function data() {
    return {
      gId: '',
      pageType: '',
      formData: {
        status: 1,
        channelIds: []
      },
      currentChannel: [],
      // 分类包含的群聊列表
      rules: {
        cnName: [{
          required: true,
          message: "请输入中文名称",
          trigger: "blur"
        }],
        enName: [{
          required: true,
          message: "请输入英文名称",
          trigger: "blur"
        }]
      },
      visible: false,
      searchKey: "",
      allChannel: [],
      // 弹窗的群聊列表
      loading: false,
      loadEnd: false,
      page: 1,
      size: 10,
      visibleSort: false,
      allCategory: [],
      //群分类数据
      isloading: false,
      submitloading: false
    };
  },
  directives: {
    infiniteScroll: infiniteScroll
  },
  components: {
    groupItem: groupItem,
    draggable: draggable
  },
  watch: {
    currentChannel: function currentChannel(newVal) {
      var _this = this;
      newVal.map(function (item, index) {
        item.sort = index + 1;
        _this.allChannel.map(function (value) {
          if (value.channelid == item.channelid) {
            _this.$set(value, 'in_category', true);
          }
        });
      });
    },
    allChannel: function allChannel(newVal) {
      var _this2 = this;
      this.currentChannel.map(function (val) {
        newVal.map(function (value) {
          if (val.channelid == value.channelid) {
            _this2.$set(value, 'in_category', true); // 自定义字段，是否已在分类里
          }
        });
      });
    }
  },
  mounted: function mounted() {
    if (this.$route.path.indexOf('addRecommend') == -1) {
      this.gId = this.$route.query.id;
      this.getDetail();
      if (this.$route.path.indexOf('viewRecommend') > -1) this.pageType = 'view';
      if (this.$route.path.indexOf('editRecommend') > -1) this.pageType = 'edit';
    }
  },
  methods: {
    getDetail: function getDetail() {
      var _this3 = this;
      IMApi.detailCategory({
        id: this.gId
      }).then(function (res) {
        // console.log(res);
        var ids = [];
        res.data.channels && res.data.channels.forEach(function (val) {
          ids.push(val.channelid);
        });
        _this3.formData = {
          id: _this3.gId,
          cnName: res.data.cnName,
          enName: res.data.enName,
          channelIds: ids,
          status: res.data.status || 0
        };
        _this3.currentChannel = res.data.channels || [];
      }).catch(function (err) {});
    },
    goBack: function goBack() {
      this.$router.back();
    },
    // validateCName(rule, value, callback) {
    //   if (/^[\u4E00-\u9FA5]+$/.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error("请输入中文格式的名称"));
    //   }
    // },
    // validateEName(rule, value, callback) {
    //   if (/^[a-zA-Z]+[a-zA-Z\s]*[a-zA-Z]$/.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error("请输入英文格式的名称"));
    //   }
    // },
    // 群聊排序结束
    dragEnd: function dragEnd(e) {
      var ids = [];
      this.currentChannel.map(function (val) {
        ids.push(val.channelid);
      });
      this.formData.channelIds = ids;
    },
    // 分类排序结束
    dragEndSort: function dragEndSort() {
      var _this4 = this;
      this.allCategory.map(function (val, idx) {
        _this4.$set(val, "sort", idx + 1);
      });
    },
    // 分类排序保存
    saveSort: function saveSort() {
      var _this5 = this;
      var ids = [];
      this.allCategory.map(function (value) {
        ids.push(value.id);
      });
      this.isloading = true;
      IMApi.sortCategory({
        ids: ids
      }).then(function (res) {
        _this5.$message.success('分类排序更改成功');
        _this5.visibleSort = false;
      }).catch(function (err) {}).finally(function () {
        _this5.isloading = false;
      });
    },
    clearSearch: function clearSearch() {
      this.visible = false;
      this.page = 1;
      this.searchKey = '';
    },
    showAllChannel: function showAllChannel() {
      this.page = 1;
      this.visible = true;
      this.loadEnd = false;
      this.getGroupsList();
    },
    // 滚动分页
    handleInfinite: function handleInfinite() {
      var top = this.$refs.scrollBox.scrollTop;
      var scrollHeight = this.$refs.scrollBox.scrollHeight;
      var clientHeight = this.$refs.scrollBox.clientHeight;
      // console.log(top, scrollHeight, clientHeight);
      if (scrollHeight - top < 5 / 4 * clientHeight && !this.loading && !this.loadEnd) {
        this.loading = true;
        this.getGroupsList();
      }
    },
    // 分类排序
    showCategorySort: function showCategorySort() {
      this.getCateList();
      this.visibleSort = true;
    },
    changeKeywords: function changeKeywords() {
      this.page = 1;
      this.getGroupsList();
    },
    // 获取群聊列表
    getGroupsList: function getGroupsList() {
      var _this6 = this;
      if (this.page == 1) this.allChannel = [];
      IMApi.getGroups({
        data: this.searchKey,
        page: this.page,
        size: this.size
      }).then(function (res) {
        // console.log(res);
        _this6.allChannel = _this6.allChannel.concat(res.records || []);
        if (!res.records || !res.records.length) {
          _this6.loadEnd = true;
        }
        _this6.page++;
      }).catch(function (err) {}).finally(function () {
        _this6.loading = false;
      });
    },
    // 获取群分类列表
    getCateList: function getCateList() {
      var _this7 = this;
      IMApi.getallgroups().then(function (res) {
        res.forEach(function (val, idx) {
          val.sort = idx + 1;
        });
        _this7.allCategory = res || [];
      });
    },
    // 添加群聊到分类
    chooseItem: function chooseItem(item) {
      // console.log(item.channelid);
      this.formData.channelIds.push(item.channelid);
      this.currentChannel.push(item);
    },
    removeItem: function removeItem(item) {
      var _this8 = this;
      this.formData.channelIds = this.formData.channelIds.filter(function (val) {
        return val != item.channelid;
      });
      this.currentChannel = this.currentChannel.filter(function (val) {
        return val.channelid != item.channelid;
      });
      this.allChannel.forEach(function (val) {
        if (val.channelid == item.channelid) _this8.$set(val, 'in_in_category', false);
      });
    },
    submit: debounce(function () {
      var _this9 = this;
      this.submitloading = true;
      this.$refs.form.validate(function (valid) {
        if (valid) {
          if (_this9.gId) {
            _this9.update();
          } else {
            _this9.add();
          }
        }
      });
    }, 500),
    // 添加分类
    add: function add() {
      var _this10 = this;
      IMApi.addCategory(this.formData).then(function (res) {
        _this10.$message.success('添加成功');
        _this10.$router.push('/im/recommend');
      }).catch(function (err) {}).finally(function () {
        _this10.submitloading = false;
      });
    },
    // 更新分类
    update: function update() {
      var _this11 = this;
      IMApi.updateCategory(this.formData).then(function (res) {
        _this11.$message.success('分类更新成功');
        _this11.$router.push('/im/recommend');
      }).catch(function (err) {}).finally(function () {
        _this11.submitloading = false;
      });
    }
  }
};