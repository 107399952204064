import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.array.sort.js";
var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "add"
  }, [_c("a-card", [_c("a-form-model", {
    ref: "form",
    attrs: {
      model: _vm.formData,
      rules: _vm.rules,
      layout: "vertical",
      labelAlign: "left",
      labelCol: {
        span: 5,
        offset: 2
      }
    }
  }, [_c("a-row", [_c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: "分类名称(中文)",
      prop: "cnName"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.pageType == "view",
      maxLength: 30
    },
    model: {
      value: _vm.formData.cnName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "cnName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.cnName"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "分类名称(英文)",
      prop: "enName"
    }
  }, [_c("a-input", {
    attrs: {
      disabled: _vm.pageType == "view",
      maxLength: 30
    },
    model: {
      value: _vm.formData.enName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "enName", typeof $$v === "string" ? $$v.trim() : $$v);
      },
      expression: "formData.enName"
    }
  })], 1), _c("a-form-model-item", {
    attrs: {
      label: "分类排序"
    }
  }, [_c("a-button", {
    attrs: {
      disabled: _vm.pageType != "edit"
    },
    on: {
      click: _vm.showCategorySort
    }
  }, [_vm._v("调整分类排序")])], 1), _c("a-form-model-item", {
    attrs: {
      label: "生效状态",
      prop: "status"
    }
  }, [_c("a-radio-group", {
    attrs: {
      disabled: _vm.pageType == "view"
    },
    model: {
      value: _vm.formData.status,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "status", $$v);
      },
      expression: "formData.status"
    }
  }, [_c("a-radio", {
    attrs: {
      value: 1
    }
  }, [_vm._v("生效")]), _c("a-radio", {
    attrs: {
      value: 0
    }
  }, [_vm._v("未生效")])], 1)], 1), _c("a-form-model-item", {
    attrs: {
      prop: "channelIds"
    }
  }, [_c("div", {
    staticClass: "group-label",
    attrs: {
      slot: "label"
    },
    slot: "label"
  }, [_c("span", [_vm._v("分类内群聊")]), _vm.pageType !== "view" ? _c("span", {
    staticClass: "add-group",
    attrs: {
      type: "primary"
    },
    on: {
      click: function click($event) {
        $event.stopPropagation();
        return _vm.showAllChannel.apply(null, arguments);
      }
    }
  }, [_vm._v("添加")]) : _vm._e()]), _c("draggable", {
    staticClass: "group-container",
    attrs: {
      delay: 50,
      animation: "300",
      sort: _vm.pageType != "view",
      scroll: true
    },
    on: {
      end: _vm.dragEnd
    },
    model: {
      value: _vm.currentChannel,
      callback: function callback($$v) {
        _vm.currentChannel = $$v;
      },
      expression: "currentChannel"
    }
  }, [_vm.currentChannel.length ? _vm._l(_vm.currentChannel, function (item, index) {
    return _c("groupItem", {
      key: item.id,
      attrs: {
        item: item,
        showDel: _vm.pageType != "view"
      },
      on: {
        remove: _vm.removeItem
      }
    });
  }) : _vm._e()], 2)], 1)], 1), _c("a-col", {
    attrs: {
      span: 12
    }
  }, [_c("a-form-model-item", {
    attrs: {
      label: ""
    }
  }, [!_vm.gId || _vm.pageType == "edit" && _vm.gId ? _c("a-button", {
    directives: [{
      name: "hasPermission",
      rawName: "v-hasPermission",
      value: ["im:recommend:save", "im:recommend:submit"],
      expression: "['im:recommend:save', 'im:recommend:submit']"
    }],
    staticStyle: {
      "margin-right": "20px"
    },
    attrs: {
      type: "primary",
      loading: _vm.submitloading
    },
    on: {
      click: _vm.submit
    }
  }, [_vm._v("提交")]) : _vm._e(), _c("a-button", {
    on: {
      click: _vm.goBack
    }
  }, [_vm._v("返回")])], 1)], 1)], 1)], 1)], 1), _c("a-modal", {
    attrs: {
      title: "添加",
      width: 560,
      centered: "",
      footer: null
    },
    on: {
      cancel: _vm.clearSearch
    },
    model: {
      value: _vm.visible,
      callback: function callback($$v) {
        _vm.visible = $$v;
      },
      expression: "visible"
    }
  }, [_c("a-row", {
    staticClass: "full-input"
  }, [_c("a-input", {
    attrs: {
      placeholder: "群名称/群id",
      allowClear: true
    },
    on: {
      change: _vm.changeKeywords
    },
    model: {
      value: _vm.searchKey,
      callback: function callback($$v) {
        _vm.searchKey = typeof $$v === "string" ? $$v.trim() : $$v;
      },
      expression: "searchKey"
    }
  }), _c("div", {
    ref: "scrollBox",
    staticClass: "search-container",
    on: {
      scroll: _vm.handleInfinite
    }
  }, [_vm._l(_vm.allChannel, function (item) {
    return _c("groupItem", {
      key: item.id,
      attrs: {
        showbtn: true,
        item: item
      },
      on: {
        choose: _vm.chooseItem
      }
    });
  }), _vm.loading ? _c("a-spin") : _vm._e()], 2)], 1)], 1), _c("DPopup", {
    attrs: {
      title: "分类排序",
      comVisible: _vm.visibleSort,
      isClosable: true,
      modalWidth: "560px"
    },
    on: {
      cancel: function cancel($event) {
        _vm.visibleSort = false;
      }
    }
  }, [_c("div", {
    attrs: {
      slot: "form"
    },
    slot: "form"
  }, [_c("draggable", {
    staticClass: "works-sort",
    attrs: {
      delay: 50,
      animation: "300"
    },
    on: {
      end: _vm.dragEndSort
    },
    model: {
      value: _vm.allCategory,
      callback: function callback($$v) {
        _vm.allCategory = $$v;
      },
      expression: "allCategory"
    }
  }, [_vm.allCategory.length ? _vm._l(_vm.allCategory, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "sort-item"
    }, [_c("span", {
      staticClass: "count"
    }, [_vm._v(_vm._s(item.sort))]), _c("span", {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.cnName))]), _c("a", {
      staticClass: "drag-icon",
      attrs: {
        href: "javascript:void(0);"
      }
    }, [_c("a-icon", {
      attrs: {
        type: "swap"
      }
    }), _vm._v(" 拖拽调整排序 ")], 1)]);
  }) : _c("a-empty")], 2), _c("div", {
    staticClass: "flex-center"
  }, [_vm.allCategory.length ? _c("a-button", {
    attrs: {
      type: "primary",
      loading: _vm.isloading
    },
    on: {
      click: _vm.saveSort
    }
  }, [_vm._v("保存")]) : _vm._e()], 1)], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };